import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=ed29d0de"
import script from "./Error404.vue?vue&type=script&lang=js"
export * from "./Error404.vue?vue&type=script&lang=js"
import style0 from "./Error404.vue?vue&type=style&index=0&id=ed29d0de&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/vercel/path0/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ed29d0de')) {
      api.createRecord('ed29d0de', component.options)
    } else {
      api.reload('ed29d0de', component.options)
    }
    module.hot.accept("./Error404.vue?vue&type=template&id=ed29d0de", function () {
      api.rerender('ed29d0de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/error/Error404.vue"
export default component.exports