<template>
  <img
    :src="appLogoImage"
    alt="Logo Seducar">
</template>

<script>
import { $themeConfig } from '@themeConfig';

export default {
  setup() {
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
};
</script>

